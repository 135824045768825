import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useQuery } from 'react-query';
import { AuthContext } from '../../context/AuthContext';
import { UserInfo } from '../../context/User';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import Selection from '../common/Selection';
import SearchInput from '../SearchInput';
import SEO from '../Seo';
import MembersPageTitle from './MembersPageTitle';
import { useDebouncedCallback } from 'use-debounce';
import {
  ResourceDocument,
  ResourceDocumentCategory,
  User
} from './NewApiTypes.generated';
import { getTreasureChestResources } from './api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

type ContentSidebarProps = {
  categories: string[];
  selectedCategories: string[];
  onSetSelectedCategory: any;
};
const ContentSidebar = (props: ContentSidebarProps) => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <div className="pb-4 border-b border-gray-200">
        <h2 className="text-xl font-semibold sans-serif text-primary">
          Categories
        </h2>
      </div>
      <div className="mt-4">
        {props.categories.map((category, i: number) => (
          <Selection
            item={{
              label: category,
              selected: props.selectedCategories.includes(category)
            }}
            setSelected={() => props.onSetSelectedCategory(category)}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

interface CollectionCountProps {
  count: number;
}

const CollectionCount = (props: CollectionCountProps) => {
  return (
    <span className="px-3 py-1 text-sm text-blue-100 rounded-full sans-serif bg-primary">
      {props.count} Items
    </span>
  );
};

interface CollectionProps {
  title: string;
  description: string;
  items: ResourceDocument[];
  user: UserInfo | User;
}

const Collection = (props: CollectionProps) => {
  return (
    <Card>
      <SEO title="Treasure Chest" />
      <div className="flex justify-between">
        <div className="">
          <h2 className="text-2xl font-semibold sans-serif text-primary">
            {props.title}
          </h2>
        </div>
        <div className="">
          <div className="flex flex-row my-auto ml-8 text-center">
            <CollectionCount count={props.items.length} />
          </div>
        </div>
      </div>
      <div>
        <p className="mt-4 leading-snug text-gray-500 sans-serif">
          {props.description}
        </p>
      </div>
      <div className="mt-4">
        <ul>
          {props.items.map((item, i) => {
            return (
              <li
                key={i}
                className="text-primary font-semibold text-lg p-4 border-b border-gray-200"
              >
                <div>
                  <div className="flex justify-between">
                    <a
                      href={item.link}
                      target="_blank"
                      className="flex justify-between hover:underline cursor-pointer"
                    >
                      <div>
                        <FontAwesomeIcon icon={faFile} />
                        <span className="sans-serif ml-4">{item.title}</span>
                      </div>
                    </a>
                  </div>
                  <p className="mt-1 text-sm text-gray-500 sans-serif">
                    {item.description}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Card>
  );
};

interface MyToolkitProps {
  path: string;
}
const MyToolkit = (props: MyToolkitProps) => {
  const auth = useContext(AuthContext);
  const { id: _userId, portalMetadata } = auth.authState as User & {
    portalMetadata: any;
  };

  const userId = portalMetadata?.userId || _userId;

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!auth.canAccessToolkit(auth.authState as User)) {
      navigate('/members/profile');
    }
  }, []);

  // const { isFetching, error, data } = useQuery('toolkitContent', () =>
  //   getToolkitResources({userId, isSchoolLeader: auth.isSchoolLeader(auth.authState as User), email: auth.authState?.email, isTestPrep: auth.isTestPrep(auth.authState as User)})
  // );

  const { isFetching, error, data } = useQuery('treasureChest', () =>
    getTreasureChestResources({
      userId,
      isSchoolLeader: auth.isSchoolLeader(auth.authState as User),
      email: auth.authState?.email || '',
      isTestPrep: auth.isTestPrep(auth.authState as User)
    })
  );

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const debounced = useDebouncedCallback(
    searchTerm => setSearchTerm(searchTerm),
    300
  );

  useEffect(() => {
    if (data?.categories) {
      const categories = data.categories.map(
        (category: ResourceDocumentCategory) => category.name
      );

      setSelectedCategories(categories as string[]);
    }
  }, [data]);

  return (
    <div>
      <div className="mb-4">
        <MembersPageTitle title="Treasure Chest" />
      </div>
      {isFetching && <LoadingDialog />}
      {!isFetching && data?.categories && (
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/4">
            <div className="sticky mb-4 top-4">
              <ContentSidebar
                selectedCategories={selectedCategories}
                onSetSelectedCategory={(category: string) => {
                  if (selectedCategories.includes(category)) {
                    setSelectedCategories(
                      selectedCategories.filter((c: string) => c !== category)
                    );
                  } else {
                    setSelectedCategories([...selectedCategories, category]);
                  }
                }}
                categories={
                  data?.categories
                    ? data.categories.map(
                        (category: ResourceDocumentCategory) => category.name
                      )
                    : []
                }
              />
            </div>
          </div>
          <div className="w-full lg:ml-8 lg:w-3/4">
            <div className="w-1/3 mb-4">
              <SearchInput
                placeholder="Search Collections"
                onChange={(e: any) =>
                  debounced.callback(e.target.value.toLowerCase())
                }
              />
            </div>
            <div className="my-4">
              {searchTerm && (
                <p className="italic text-gray-500">
                  Showing results for filter "{searchTerm}"
                </p>
              )}
            </div>
            <div className="mb-8">
              {data?.categories &&
                data.categories
                  .filter((category: ResourceDocumentCategory) =>
                    selectedCategories.includes(category.name)
                  )
                  .filter((category: ResourceDocumentCategory) => {
                    return (
                      category.name.toLowerCase().includes(searchTerm) ||
                      category.documents?.some(item =>
                        item?.title.toLowerCase().includes(searchTerm)
                      )
                    );
                  })
                  .map((collection: ResourceDocumentCategory, i: number) => {
                    return (
                      <div className="mb-4" key={i}>
                        <Collection
                          title={collection.name}
                          description={''}
                          items={collection.documents as ResourceDocument[]}
                          user={auth.authState as User}
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyToolkit;
